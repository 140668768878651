<template>
	<li
		class="accordion-item"
		tabindex="0"
		@keyup.enter="keytriggerHeading"
		@keyup.tab="keyboardFocus = true"
		@focusout="keyboardFocus = false"
		:class="[
			{
				'keyboard-focus': keyboardFocus,
			},
			{
				'multiline-heading': multilineHeading,
			},
			uncontained ? 'uncontained' : 'contained',
			loading,
			size,
			shadow && `shadow-${shadowSize}`,
			{
				dark,
			},
		]"
		:style="style"
	>
		<span
			v-if="badgeText || $slots.badge"
			class="accordion-item-badge"
		>
			<slot
				name="badge"
				:badgeText="'Super tilbud' || badgeText"
			>
				<TnBadge
					class="accordion-item-badge"
					:size="size === 's' ? 'xs' : 's'"
					compact
					:color="badgeColor"
					>{{ badgeText }}</TnBadge
				>
			</slot>
		</span>
		<div class="accordion-wrap">
			<div
				class="accordion-heading-wrapper"
				@click="clickHeading"
			>
				<slot
					name="heading"
					:slotProps="$props"
				>
					<div
						class="accordion-item-heading"
						:class="{
							dark: dark,
						}"
					>
						<div
							v-if="$slots.icon || icon"
							class="left-col-icon"
						>
							<slot name="icon">
								<TnIcon
									v-if="icon"
									:name="icon"
								/>
							</slot>
						</div>

						<div class="texts">
							<div class="left-col">
								<div
									:class="{
										'center-align-items': !($props.title && $props.text),
									}"
								>
									<TnHeading
										:size="textSizes.titleSize"
										type="h4"
										class="heading"
										responsive
										:dark="dark"
										>{{ $props.title }}</TnHeading
									>
									<TnParagraph
										class="sub-title"
										:class="{
											dark: dark,
										}"
										:size="textSizes.subTitleSize"
										>{{ $props.text }}</TnParagraph
									>
								</div>
							</div>

							<div
								class="right-col"
								:class="{
									empty: !($props.secondaryTitle || $props.secondaryText),
								}"
							>
								<div
									v-if="$props.secondaryTitle || $props.secondaryText"
									:class="{
										'center-align-items': !($props.secondaryTitle && $props.secondaryText),
									}"
								>
									<TnHeading
										type="h4"
										:size="textSizes.titleSize"
										class="heading"
										responsive
										:dark="dark"
										>{{ $props.secondaryTitle }}</TnHeading
									>
									<TnParagraph
										class="sub-title"
										:class="{
											dark: dark,
										}"
										:size="textSizes.subTitleSize"
										>{{ $props.secondaryText }}</TnParagraph
									>
								</div>
							</div>
						</div>

						<div class="right-col-icon">
							<slot
								name="chevron"
								:open="isOpen"
							>
								<TnIcon
									v-if="!isOpen"
									name="chevron-down"
									:size="size === 's' ? 'm' : 'l'"
									key="chevron-down"
								/>
								<TnIcon
									v-if="isOpen"
									name="chevron-up"
									:size="size === 's' ? 'm' : 'l'"
									key="chevron-up"
								/>
							</slot>
						</div>
					</div>
				</slot>
			</div>

			<TnTransitionHeight :show-if="isOpen">
				<section
					class="accordion-item-content"
					:class="{
						dark: dark,
					}"
				>
					<slot></slot>
				</section>
			</TnTransitionHeight>
		</div>
	</li>
</template>

<script>
import { defineComponent } from "vue";

import variables from "@/assets/tokens/json/variables.json";

export default defineComponent({
	name: "TnAccordionItem",

	inject: ["onHeadingClick", "openItems", "items"],

	props: {
		/**
		 * The title displayed in the left-hand column
		 */
		title: String,
		/**
		 * The text displayed in the left-hand column (below title)
		 */
		text: String,
		/**
		 * The secondary-title displayed in the right-hand column
		 */
		secondaryTitle: String,
		/**
		 * The secondary-text displayed in the right-hand column (below title)
		 */
		secondaryText: String,
		/**
		 * Name of the icon (from the TnIcon-library) to display on the left-hand side of the expandable accordion
		 */
		icon: String,
		/**
		 * The text displayed in the badge
		 */
		badgeText: String,
		/**
		 * The color of the badge
		 * @see TnBadge for available colors
		 */
		badgeColor: {
			type: String,
			default: "blue",
		},
		/**
		 * Show loading animation
		 */
		loading: Boolean,
		/**
		 * The size of the Accordion
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
		},
		/**
		 * Uncontained - select the style if it is contained or uncontained
		 */
		uncontained: {
			type: Boolean,
			default: false,
		},
		/**
		 * The size of the box-shadow for the card
		 * @values s, m, l
		 */
		shadow: {
			type: [Boolean, String],
			default: null,
		},
		/**
		 * Dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		/**
		 * The background-color of the AccordionItem
		 */
		color: {
			type: String,
		},
	},

	data() {
		return {
			keyboardFocus: false,
		};
	},
	computed: {
		isOpen() {
			return this.openItems.includes(this.$.uid);
		},
		shadowSize() {
			if (typeof this.shadow === "boolean") {
				return "m";
			}
			return this.shadow;
		},
		multilineHeading() {
			return this.text?.length > 0 || (this.secondaryText && this.secondaryText.length > 0);
		},
		textSizes() {
			let titleSize;
			let subTitleSize;
			if (this.size === "s") {
				titleSize = this.multilineHeading ? "2xs" : "xs";
				subTitleSize = "xs";
			} else if (this.size === "m") {
				titleSize = this.multilineHeading ? "xs" : "s";
				subTitleSize = "m";
			} else if (this.size === "l") {
				titleSize = this.multilineHeading ? "xs" : "m";
				subTitleSize = "m";
			}
			return { titleSize, subTitleSize };
		},
		style() {
			return {
				backgroundColor: variables[this.color?.trim().toLowerCase()] || this.color,
			};
		},
	},
	created() {
		this.items.push(this.$.uid);
	},

	methods: {
		clickHeading() {
			this.onHeadingClick(this.$.uid);
		},
		keytriggerHeading() {
			this.keyboardFocus = true;
			this.clickHeading();
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/global-style/scss/mixins/all" as mixins;
@use "@/assets/typography/scss/mixins" as typography;

.accordion-item {
	--accordion-foreground-color: #{variables.$color-neutrals-black};
	--accordion-subtitle-color: #{variables.$color-neutrals-600-shade};
	--accordion-focus-color: #{variables.$color-cta-focus};
	--accordion-hover-color: #{variables.$color-cta-hover};

	margin-bottom: 18px;
	position: relative;
	list-style: none;
	color: var(--accordion-foreground-color);

	@include mixins.loading;

	&.dark {
		--accordion-foreground-color: #{variables.$color-neutrals-100-tint};
		--accordion-subtitle-color: #{variables.$color-neutrals-100-tint};
		--accordion-focus-color: #{variables.$color-cta-dark-focus};
		--accordion-hover-color: #{variables.$color-cta-dark-hover};
	}

	.accordion-item-content {
		:deep(p) {
			color: variables.$color-neutrals-600-shade;
		}

		&.dark {
			:deep(p) {
				color: variables.$color-neutrals-100-tint;
			}
		}
	}

	&.shadow {
		&-s {
			@include mixins.shadow(s);
		}

		&-m {
			@include mixins.shadow(m);
		}

		&-l {
			@include mixins.shadow(l);
		}
	}

	&.uncontained {
		border-radius: 8px;

		.accordion-wrap {
			border-bottom: variables.$color-neutrals-100-tint solid 1px;
		}

		&.dark {
			.accordion-wrap {
				border-bottom: variables.$color-neutrals-700-shade solid 1px;
			}
		}
	}

	.sub-title {
		color: var(--accordion-subtitle-color);

		@include mixins.breakpoint(mobile) {
			@include typography.font-text-xs;
		}
	}

	&.contained {
		background-color: variables.$color-neutrals-50-tint;
		border-radius: 16px;

		&.dark {
			background-color: variables.$color-neutrals-1000-shade;
		}
	}

	&:focus {
		outline: none;

		&.keyboard-focus {
			outline: 2px solid var(--accordion-focus-color);

			&.uncontained {
				outline-offset: 8px;
			}

			.accordion-item-heading {
				&,
				p,
				svg {
					color: var(--accordion-focus-color);
				}
			}
		}
	}

	&-badge {
		position: absolute !important;
		width: max-content !important;
		left: 8px;
	}

	&-heading {
		display: flex;
		align-items: center;

		/* Make text non-selectable */

		/* Safari */
		user-select: none; /* Standard */

		.heading {
			font-family: variables.$font-family-telenor-ui;

			@include mixins.breakpoint(above-mobile) {
				margin-bottom: variables.$spacing-xs;
			}
		}

		.texts {
			display: flex;
			flex-basis: 100%;
			justify-content: space-between;
			gap: variables.$spacing-xl;

			@include mixins.breakpoint(mobile) {
				flex-direction: column;
				gap: variables.$spacing-xs;
			}
		}

		.left-col,
		.right-col {
			flex-basis: 100%;
		}

		.left-col {
			text-align: left;
			display: flex;

			&-icon {
				display: flex;
				align-items: center;
				height: 100%;
				margin-right: 16px;
				object-fit: contain;

				img {
					max-height: 48px;
					max-width: 48px;
				}
			}
		}

		.right-col {
			display: flex;

			&.empty {
				flex-basis: 0;
			}

			&-icon {
				display: flex;
				align-items: center;
				height: 100%;
				margin-left: 16px;
			}

			@include mixins.breakpoint(above-mobile) {
				justify-content: flex-end;
				text-align: right;
			}
		}

		&-titles {
			align-content: flex-end;
		}

		.gap {
			flex-basis: variables.$spacing-xl;
		}

		.center-align-items {
			display: flex;
			align-items: center;

			* {
				margin-bottom: 0;
			}
		}
	}

	@include mixins.breakpoint(above-mobile) {
		.accordion-heading-wrapper {
			&:hover {
				.accordion-item-heading {
					&,
					* {
						color: var(--accordion-hover-color);
					}
				}

				.uncontained {
					border-bottom: variables.$color-cta-hover solid 1px;

					&.dark {
						color: variables.$color-cta-dark-hover;
						border-bottom: variables.$color-cta-dark-hover solid 1px;
					}
				}
			}
		}
	}
}

.accordion-heading-wrapper {
	cursor: pointer;
}

.s {
	&.contained {
		.accordion-heading-wrapper {
			padding: 24px;
		}

		.accordion-item-content {
			padding: 0 24px 24px;
		}
	}

	&.uncontained {
		.accordion-heading-wrapper {
			padding: 24px 0;
		}

		.accordion-item-content {
			padding-bottom: 24px;
		}
	}
}

.m {
	&.contained {
		.accordion-heading-wrapper {
			padding: 26px;
		}

		.accordion-item-content {
			padding: 0 26px 26px;
		}
	}

	&.uncontained {
		.accordion-heading-wrapper {
			padding: 26px 0;
		}

		.accordion-item-content {
			padding-bottom: 26px;
		}
	}
}

.l {
	&.contained {
		.accordion-heading-wrapper {
			padding: 28px;
		}

		.accordion-item-content {
			padding: 0 28px 28px;
		}
	}

	&.uncontained {
		.accordion-heading-wrapper {
			padding: 28px 0;
		}

		.accordion-item-content {
			padding-bottom: 28px;
		}
	}
}

.accordion-content {
	backface-visibility: hidden;
	z-index: 1;
	overflow: hidden;

	&-enter-from {
		opacity: 0;
	}

	&-enter-active {
		will-change: opacity;
		transition: opacity 0.5s ease;
		overflow: hidden;
	}

	&-leave-to {
		opacity: 0;
	}

	&-leave-active {
		will-change: opacity;
		transition: opacity 0.5s ease;
		overflow: hidden;
	}
}
</style>
