<template>
	<ul class="accordion">
		<!-- @slot This is the default slot. Expects/accepts a list of AccordionItems -->
		<slot></slot>
	</ul>
</template>

<script>
import { computed, defineComponent } from "vue";

/**
 * A accordion allows users to toggle the display of sections of content and is useful for reducing vertical space with large amounts of information.
 *
 * The default functionality of the component is to only display one expansion-panel body at a time; however, with the **_multiple_** property, the expansion-panel can remain open until explicitly closed.
 *
 * The Accordion component consists of a parent container (**TnAccordion**) that wraps a number of items (**TnAccordionItem**).
 *
 * The component should typically be used with this nested structure;
 *
 * - TnAccordion
 *     - TnAccordionItem
 *         - content (default `<slot>`)
 *     - ... (_more items_)
 *
 *
 * Each TnAccordionItem accepts a set of properties for the heading text (both title and text) and is very versatile with
 * optional `<slots>` for overriding design and content, such as ***icon***, ***chevron***, ***badge***, etc..
 *
 * @displayName TnAccordion, TnAccordionItem
 */
export default defineComponent({
	name: "TnAccordion",

	provide() {
		return {
			onHeadingClick: this.onHeadingClick,
			openItems: computed(() => this.openItems),
			items: this.items,
		};
	},

	props: {
		/**
		 * If set allows *multiple* AccordionItems to be expanded
		 */
		multiple: {
			type: Boolean,
			default: false,
		},
		/**
		 * If set the first AccordionItem within the Accordion is opened by default
		 */
		firstItemOpen: {
			type: Boolean,
			default: false,
		},
		/**
		 * Set to open all accordion items by default
		 */
		allItemsOpen: {
			type: Boolean,
			default: false,
		},
	},

	emits: [
		/**
		 * Event fired when item was toggled. **Returns** *$.uid* of TnAccordionItem
		 */
		"item-toggled",
	],

	data() {
		return {
			openItems: [],
			items: [],
		};
	},

	mounted() {
		this.$nextTick(() => {
			if (this.firstItemOpen) {
				this.openItems.unshift(this.items[0]);
			}

			if (this.allItemsOpen) {
				this.openItems = [...this.items];
			}
		});
	},

	methods: {
		onHeadingClick(id) {
			if (this.openItems.includes(id)) {
				this.openItems.splice(this.openItems.indexOf(id), 1);
			} else {
				if (this.multiple) {
					this.openItems.push(id);
				} else {
					this.openItems = [];
					this.openItems.push(id);
				}
			}

			this.$emit("item-toggled", id);
		},
	},
});
</script>

<style lang="scss" scoped>
.accordion {
	padding: 0;
}
</style>
